
table {
    width: 100%;
    border-spacing: 1px;
    text-align: left;
    margin: 0 auto;
}


table thead th {
    text-align: left;
    /*border: 1px solid #737070;*/
}

table td {
    /*border: thin solid #737070;*/
    text-align: left;
}


.negative {
    color: #ff0000;
    text-transform: capitalize;

}

.positive {
    color: #079407;
    text-transform: capitalize;
}

.neutral {
    color: #9f9fb7;
    text-transform: capitalize;
}

.number {
    text-align: right;
    padding-right: 20px;
}

.sentence {
    text-align: left;
    padding-left: 20px;
}

.nouns {
    width: fit-content;
    word-wrap: break-word;
}

.verbs {
    width: fit-content;
    word-wrap: break-word;
}

.adjectives {
    width: min-content;
    word-wrap: break-word;
}

.video {
    object-fit: none;
    max-width: 110px
}

.highlight {
    color: #469DF5;
}


.duration {
    font-size: 15px;
    padding-right: 10px;
    min-width: 100px;
    text-align: right;
}

.duration p {
    margin: 0;
    padding: 0;
}

.white {
    color: white;
}

.black {
    color: black;
}

.red {
    color: red;
}
.orange {
    color: orange;
}