.hr {
    display: inline-block;
    margin: 1em  0 1em 0;
}

.hr:after {
    content: '';
    display: block;
    border-top: 1px solid #737070;
    margin-top: 0.3em;
}