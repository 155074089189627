table {
    width: 95%;
    border-spacing: 20px;
    /*border: 1px solid #737070;*/
    text-align: left;
    margin: 0 auto;
    table-layout: fixed;
    word-wrap: break-word;
}


table thead {
    /* make thead sticky */
    height: 60px;
    position: sticky;
    border: 1px solid #737070;
}


table td {
    border-top: thin solid #737070;
    padding: 4px;
}

